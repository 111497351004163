<template>
  <section class="extend-wrap">
    <div class="content">
      <div class="banner">
        <a-carousel arrows autoplay>
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px">
              <LeftCircleOutlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <RightCircleOutlined />
            </div>
          </template>
          <div
            class="banner-item"
            v-for="(item, index) in bannerData"
            :key="index"
          >
            <div
              class="img"
              @click="toBannerURL(item)"
              :style="[
                (item.jumpType == 1 && item.jumpUrl == '') ||
                (item.jumpType == 2 && item.resourceId == 0)
                  ? ''
                  : 'cursor: pointer;',
              ]"
            >
              <img :src="item.coverPath" alt="" />
            </div>
          </div>
        </a-carousel>
      </div>
      <div class="study">
        <div class="study-head">
          <SendOutlined
            style="
              font-size: 16px;
              margin: 0px 10px 4px 4px;
              transform: rotate(-45deg);
            "
          />
          <span>学习情况</span>
        </div>
        <div class="study-body">
          <div class="name" v-if="user.realName">
            <OpenData type="userName" :openid="user.realName" />
          </div>
          <div class="department" v-if="user.departmentName">
            <OpenData type="departmentName" :openid="user.departmentName" />
            <template v-if="user.positionName"
              >| {{ user.positionName }}</template
            >
          </div>
          <div class="row">
            <div class="card">
              <p>
                <strong>
                  {{ extendData.doneCourses || 0 }}
                </strong>
              </p>
              <p>完成在线课程数</p>
            </div>
            <div class="card">
              <p>
                <strong>
                  {{
                    extendData.totalTime > 0
                      ? (extendData.totalTime / 60).toFixed(0)
                      : 0
                  }} </strong
                >min
              </p>
              <p>总学习时长</p>
            </div>
          </div>
          <div class="row">
            <div class="card">
              <p>
                <strong> {{ extendData.monthRanks || 0 }} </strong>
              </p>
              <p>本月学习时长排行</p>
            </div>
            <div class="card">
              <p>
                <strong>
                  {{
                    extendData.monthTime > 0
                      ? (extendData.monthTime / 60).toFixed(0)
                      : 0
                  }} </strong
                >min
              </p>
              <p>本月学习时长</p>
            </div>
          </div>
          <div class="more" @click="toMine">查看更多</div>
        </div>
      </div>
    </div>
  </section>

  <section class="task-wrap" v-if="taskData.length">
    <div class="content">
      <ModTit title="学习任务" :more="{ name: 'mine' }" />
      <div class="cards clearfix">
        <template v-for="item in taskData" :key="item.taskId">
          <TaskCard :dataSource="item" />
        </template>
      </div>
    </div>
  </section>

  <section class="recommend-wrap">
    <div class="content">
      <ModTit title="推荐课程" :more="{ path: '/course' }" />
      <div class="cards clearfix">
        <a-spin :spinning="recommendCourseLoading">
          <template v-if="recommendCourseData.length">
            <div
              class="item fl"
              v-for="item in recommendCourseData"
              :key="item.courseId"
            >
              <CourseCard :dataSource="item" />
            </div>
          </template>
          <a-empty v-else style="padding: 50px 0" />
        </a-spin>
      </div>
    </div>
  </section>

  <section class="course-wrap">
    <div class="content">
      <ModTit title="最新课程" :more="{ path: '/course' }" />
      <div class="cards clearfix">
        <a-spin :spinning="courseLoading">
          <template v-if="courseData.length">
            <div
              class="item fl"
              v-for="item in courseData"
              :key="item.courseId"
            >
              <CourseCard :dataSource="item" sourceType="home" />
            </div>
          </template>
          <a-empty v-else style="padding: 50px 0" />
        </a-spin>
      </div>
    </div>
  </section>

  <section class="ranking-wrap">
    <div class="content">
      <ModTit :more="{ path: '/mine/ranking' }">
        <template v-slot:left>
          <h3 class="ranking-tit">
            排行榜
            <span class="ranking-reload">
              <a-tooltip title="排行榜刷新">
                <SyncOutlined
                  style="font-size: 16px"
                  @click="rankingChange(rankingActive, 'refresh')"
                />
              </a-tooltip>
            </span>
          </h3>
        </template>
      </ModTit>
      <div class="card">
        <div class="ctrl">
          <h5>
            榜单合计<strong>{{ rankingTotals }}</strong
            >人
          </h5>
          <div class="menu">
            <div
              class="item"
              :class="{ active: rankingActive === item.code }"
              @click="rankingChange(item.code)"
              v-for="item in rankingMenu"
              :key="item.code"
            >
              <CarryOutOutlined />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="main">
          <a-spin :spinning="rankingLoading">
            <template v-if="rankingData.length">
              <div
                class="item"
                v-for="(item, index) in rankingData"
                :key="index"
              >
                <div class="icon">
                  <img
                    v-if="index <= 2"
                    :src="
                      require(`@/assets/image/index_ranking_${index + 1}.png`)
                    "
                    alt="icon"
                  />
                  <div v-else class="num">{{ index + 1 }}</div>
                </div>
                <img class="avatar" :src="item.portrait" alt="avatar" />
                <div class="user">
                  <p class="name">
                    <OpenData type="userName" :openid="item.realName" />
                  </p>
                  <p class="department">
                    <template
                      v-for="(str, strIndex) in item.departmentPath.split('/')"
                      :key="strIndex"
                    >
                      <OpenData type="departmentName" :openid="str" />
                      <span
                        v-if="
                          strIndex + 1 < item.departmentPath.split('/').length
                        "
                        >/</span
                      >
                    </template>
                  </p>
                </div>
                <div class="period">
                  <p class="label">学习时长</p>
                  <p class="num">{{ formatTime(item.totalTime) }}</p>
                </div>
              </div>
            </template>
            <a-empty v-else style="padding: 50px 0" />
          </a-spin>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ModTit from "@/components/layout/ModTit.vue";
import TaskCard from "@/components/task/TaskCard.vue";
import CourseCard from "@/components/course/CourseCard.vue";
import OpenData from "@/components/OpenData.vue";
import { getBanner, getRanking } from "@/api/other";
import { extend, companyInfo } from "@/api/user";
import { newTaskList } from "@/api/project";
import { courseList, recommendCourseList } from "@/api/course";
import { formatTime } from "@/utils/tools";
import { useRouter } from "vue-router";
import { myStudyTab } from "@/utils/business";
export default {
  components: {
    ModTit,
    TaskCard,
    CourseCard,
    OpenData,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    // 轮播图
    const bannerData = ref([]);
    getBanner().then((res) => {
      if (res.ret === 0) {
        res.data = res.data.filter((item) => {
          return item.bannerType == 1;
        });
        bannerData.value = res.data;
      }
    });
    let urlStr = "";
    const toBannerURL = (item) => {
      if (item.jumpType == 1) {
        if (item.jumpUrl != "") {
          urlStr =
            item.jumpUrl.indexOf("http") != -1
              ? item.jumpUrl
              : "http://" + item.jumpUrl;
          if (store.getters.companyInfo.useNewWindow == 1) {
            window.open(
              urlStr.includes("?")
                ? `${urlStr}&ddtab=true`
                : `${urlStr}?ddtab=true`
            );
          } else {
            window.location.href = urlStr;
          }
        }
      } else {
        if (item.resourceId != 0) {
          switch (item.resourceType) {
            case 1: //知识
              urlStr = "/doc/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
            case 2: //课程
              urlStr = "/course/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
            case 34: //项目
              urlStr = "/project/detail?id=" + item.resourceId;
              if (store.getters.companyInfo.useNewWindow == 1) {
                window.open(urlStr + "&ddtab=true");
              } else {
                window.location.href = urlStr;
              }
              break;
          }
        }
      }
    };

    // 用户信息
    const user = computed(() => store.getters.userInfo);

    const toMine = () => {
      router.push({
        name: "mine",
      });
    };

    // 学习情况
    const extendData = ref({
      doneCourses: 0,
    });
    extend().then((res) => {
      extendData.value = res.data;
    });

    // 学习任务
    const taskData = ref([]);
    const getTask = async () => {
      const res = await companyInfo();
      const menu = res.ret === 0 ? res.data.menu : [];
      let hasProject = menu.includes("project");
      let hasPlan = menu.includes("plan");
      if (hasProject && !hasPlan) {
        myStudyTab[1].taskType = [1, 11];
      } else if (!hasProject && hasPlan) {
        myStudyTab[1].menuType = "plan";
        myStudyTab[1].taskType = [14];
      }
      let type = [];
      myStudyTab.filter((item, i) => {
        if (menu.includes(item.menuType) || i === 0) {
          i !== 0 && type.push(...item.taskType);
          return true;
        }
      });
      const params = {
        name: "",
        page: 1,
        pageSize: 4,
        // taskType: type,
      };
      newTaskList(params).then((res) => {
        if (res.ret === 0) {
          taskData.value = res.data.list || [];
        }
      });
    };
    getTask();

    // 推荐课程
    const recommendCourseData = ref([]),
      recommendCourseLoading = ref(true);
    recommendCourseList().then((res) => {
      recommendCourseLoading.value = false;
      if (res.ret === 0) {
        recommendCourseData.value = res.data || [];
      }
    });

    // 最新课程
    const courseData = ref([]),
      courseLoading = ref(true);
    courseList({
      order: 1,
    }).then((res) => {
      courseLoading.value = false;
      if (res.ret === 0) {
        let list = res.data.list || [];
        courseData.value = list.slice(0, 8);
      }
    });

    // 排行榜
    let rankingMenu = [
      {
        name: "全部排行榜",
        code: 1,
      },
      {
        name: "今日排行榜",
        code: 2,
      },
      {
        name: "本周排行榜",
        code: 3,
      },
      {
        name: "本月排行榜",
        code: 4,
      },
    ];
    const rankingData = ref([]),
      rankingTotals = ref(0),
      rankingLoading = ref(true),
      rankingActive = ref(1),
      getRankings = (date, type = "none") => {
        getRanking({
          date: date,
          page: 1,
          pageSize: 5,
          refresh: type == "refresh",
        }).then((res) => {
          rankingLoading.value = false;
          if (res.ret === 0) {
            let list = res.data.ranks || [];
            rankingData.value = list.slice(0, 10);
            rankingTotals.value = res.data.totals;
          }
        });
      },
      rankingChange = (code, type = "none") => {
        rankingActive.value = code;
        getRankings(code, type);
      };
    getRankings(1);

    return {
      bannerData,
      user,
      extendData,
      taskData,
      recommendCourseData,
      recommendCourseLoading,
      courseData,
      courseLoading,
      rankingMenu,
      rankingData,
      rankingTotals,
      rankingLoading,
      rankingActive,
      rankingChange,
      toBannerURL,
      formatTime,
      toMine,
    };
  },
};
</script>

<style lang="less" scoped>
::v-deep(.mod-tit) {
  padding: 24px 0 24px;
  h3 {
    font-size: 16px;
    color: #1b2e5a;
    font-family: Microsoft YaHei;
  }
}
.extend-wrap {
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    padding: 20px 0 0;
    .banner {
      width: 894px;
      height: 400px;
      font-size: 0;
      border-radius: 6px;
      overflow: hidden;
      background-color: #e7e8ea;
      ::v-deep(.custom-slick-arrow) {
        width: 28px;
        height: 28px;
        font-size: 28px;
        color: #fff;
        z-index: 1;
        opacity: 0.3;
        transition: opacity 0.5s;
        &:before {
          display: none;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      &-item {
        .img {
          .mixinImgWrap(894px; 400px);
        }
      }
    }
    .study {
      width: 282px;
      height: 198px;
      &-head {
        .mixinFlex(flex-start; center);
        height: 56px;
        padding: 0 16px;
        border-radius: 6px;
        background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
        font-size: 16px;
        font-weight: 500;
        color: @color-theme;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
      &-body {
        height: 344px;
        padding: 16px;
        background-color: #fff;
        line-height: 1.8;
        border-radius: 6px;
        .name {
          font-size: 16px;
        }
        .department {
          color: #a4a4a4;
          .mixinEllipsis(24px);
        }
        .row {
          .mixinFlex(space-between);
          padding: 10px 0;
          .card {
            background-color: rgba(216, 216, 216, 0.2);
            width: 118px;
            height: 80px;
            border-radius: 6px;
            padding: 0 10px;
            .mixinFlex(center; flex-start; column);
            p {
              font-size: 12px;
              margin-bottom: 0;
              strong {
                font-size: 20px;
                font-weight: bold;
                font-family: DINAlternate-Bold, DINAlternate;
                padding-right: 2px;
              }
            }
          }
        }
        .more {
          width: 204px;
          height: 44px;
          font-weight: 500;
          color: #fff;
          .mixinFlex(center;center);
          background: @color-theme;
          border-radius: 22px;
          margin: 12px auto 0;
          cursor: pointer;
        }
      }
    }
  }
}

.task-wrap {
  .content {
    .mixinWrap();
    .cards {
      .clearfix();
      ::v-deep(.card) {
        margin-right: 24px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.recommend-wrap,
.course-wrap {
  .content {
    .mixinWrap();
    .cards {
      .item {
        margin-right: 24px;
        margin-bottom: 24px;
        ::v-deep(.card) {
          height: 260px;
          .content {
            padding: 10px 14px;
            h5 {
              .mixinEllipsis(22px);
              display: block;
            }
            .info .item .teacher img {
              width: 20px;
              height: 20px;
            }
          }
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}

.ranking-wrap {
  .content {
    .mixinWrap();
    padding: 0 0 40px;
    .ranking-tit {
      margin-bottom: 0;
    }
    .ranking-reload {
      margin-left: 6px;
      color: #999;
    }
    .card {
      .mixinFlex(space-between);
      border-radius: 6px;
      overflow: hidden;
      .ctrl {
        width: 282px;
        height: 580px;
        background-size: 100% 100%;
        background-image: url("../assets/image/home/ranking-bg.png");
        h5 {
          color: @color-theme;
          font-size: 14px;
          margin: 40px 0 30px 40px;
          strong {
            font-size: 20px;
            font-weight: normal;
          }
        }
        .menu {
          color: @color-theme;
          .item {
            margin-left: 40px;
            padding-left: 22px;
            width: 242px;
            height: 60px;
            line-height: 60px;
            border-radius: 6px 0 0 6px;
            font-size: 14px;
            cursor: pointer;
            user-select: none;
            transition: all 0.1s;
            span:last-child {
              margin-left: 24px;
            }
            &.active {
              background-color: #fff;
              color: @color-theme;
            }
          }
        }
      }
      .main {
        width: calc(100% - 282px);
        height: 580px;
        background-color: #fff;
        padding: 10px 26px;
        .item {
          height: 56px;
          .mixinFlex(space-between; center);
          border-bottom: 1px solid #f6f6f6;
          .icon {
            width: 28px;
            img {
              widows: 28px;
            }
            .num {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              color: #999;
              background-color: #ededed;
              font-size: 14px;
              .mixinFlex(center; center);
            }
          }
          .avatar {
            width: 38px;
            height: 38px;
            border-radius: 50%;
          }
          .user {
            width: calc(100% - 380px);
            line-height: 1.4;
            .name {
              color: #202020;
              font-size: 15px;
              margin: 0;
            }
            .department {
              color: #999;
              font-size: 13px;
              margin: 4px 0 0 0;
            }
          }
          .period {
            width: 250px;
            text-align: right;
            .label {
              color: #999;
              font-size: 13px;
              margin: 0;
            }
            .num {
              color: #ff7300;
              font-size: 17px;
              margin: 0;
              font-weight: bold;
              font-family: DINAlternate-Bold, DINAlternate;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.course-wrap,
.ranking-wrap {
  .mod-tit {
    padding-top: 0;
  }
}
</style>
